/*
  AboutPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import './ComponentPop.scss';

// images

export default class ComponentPop extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      active: false,
  
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({

      active: false,

     

    });
    function modal_display(){
      let modal = document.getElementById('pop-up-container-two');
      if(!sessionStorage.getItem('pop-up-displayed')){
        modal.classList.remove('pop-show');
        sessionStorage.setItem('pop-up-displayed','1');
      }
      else {
        // nothing
      }
    }
    modal_display()

    function close_modal_two(){
      let cross = document.getElementById('close-pop-two');
      let filter = document.getElementById('main-pop-filter');
      let modal = document.getElementById('pop-up-container-two');

      cross.addEventListener('click' , function(){
        modal.classList.add('pop-hide');
      });
      filter.addEventListener('click' , function(){
        modal.classList.add('pop-hide');
      });
    }
    close_modal_two()
  
  }

  render(){

  const {
    marginTop
  } = this.props

  return (
    <div className="full-bleed-pop pop-show" id="pop-up-container-two">
      
      <span className="filter" id="main-pop-filter"></span>
      <div className="the-pop-up">
        <span>
          
          <img src="https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/TWIN+FIN/cancel+(1).png" alt="x" id="close-pop-two" className="close-pop-two"/>
          <h4>Book to Claim Your Father's Day Offer this Sunday!</h4>
          <Link to="/group-bookings/">
          <img src="https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/TWIN+FIN/200825_TWIN+FIN_FATHERS+DAY+ADS-04-web.jpeg" className="banner"/>
          <button>BOOK NOW</button>
          </Link>
        </span>
      </div>
     </div>
    );
  }
}