/*
  LandingPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './LandingPage.scss';

// images

import Heading from '../../Media/svg/CONTACT_US.svg';


export default class ContactPage extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {
   
    };
  }
  componentDidMount() {
      window.scrollTo(0, 0);
    

    }
 

  render(){


  return (


     <div className="landing-page">
         <div className="landing-wrapper">
          <div className="heading">
            <img src={Heading} alt="Contact Us" className="heading"/>
          </div>
          <div className="content contact-wrapper">
            <form>
              <div className="form-double-row">
                <div>
                  <label for="full-name">Full Name*</label>
                  <input 
                  type="text" 
                  id="full-name" 
                  name="full-name"
                  />
                </div>
                <div>
                  <label for="phone">Phone*</label>
                 <input 
                 type="text" 
                 id="phone" 
                 name="phone"
                 />
                </div>
              </div>
               <div className="form-double-row">
                <div>
                  <label>Email*</label>
                  <input 
                  type="email" 
                  id="email" 
                  name="email"
                  />
                </div>
                <div>
                
                </div>
              </div>
               <div className="form-double-row">
                <div>
                 <label for="requested-date">Requested Dates</label>
                  <input 
                  type="date" 
                  id="requested-dates" 
                  name="requested-dates"
                  />
                </div>
                <div>
                 <label for="estimated-numbers">Estimated Numbers</label>
                 <input 
                 type="number" 
                 id="estimated-numbers" 
                 name="estimated-numbers"
                 />
                </div>
              </div>
              <div className="form-row">
                <label for="message">Any Additional Questions</label>
                <textarea 
                name="message" 
                id="message"
                ></textarea>
              </div>
               <div className="form-row">
                <label for="diet-requirements">Dietary Requirements</label>
                <input 
                type="text" 
                name="diet-requirements" 
                id="diet-requirements" 
                />
              </div>
              <div className="form-row">
                <input 
                type="submit" 
                id="submit" 
                className="submit-button"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}