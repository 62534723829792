import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
close_modal();



function close_modal(){
  var cross = document.getElementById('close-pop');
  var modal = document.getElementById('pop-up-container');

  cross.addEventListener('click' , function(){
    console.log('clicked');
    modal.classList.add('pop-hide');
  });
}

