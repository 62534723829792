/*
  ContactPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {Helmet} from "react-helmet";
import ComponentHeader from './../ComponentHeader';
import ComponentPop from './../ComponentPop';
import './Careers.scss';
import '../ContactPage/ContactPage.scss';
import "react-datepicker/dist/react-datepicker.css";

// Lib.
import submitCareers from '../../lib/submitCareers';

// images

import Tick from '../../Media/svg/check-mark.svg';

// Constants.
//
const CONTACT_URL = 'https://cajvufvld5.execute-api.ap-southeast-2.amazonaws.com/twinfin-careers-form';
const MAX_FILE_SIZE = 10000000;




export default class Careers extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {

      first_name: '',
      surname: '',
      d_o_b: '',
      age: '',
      street_address: '',
      post_code: '',
      suburb: '',
      email: '',
      mobile_number: '',
      preferred_number: '',
      residency_type: '',
      visa:false,
      residency:false,
      visa_status: '',
      your_status: '',
      length_stay: '',
      start_date: '',
      hours_week: '',
      day_monday: '',
      day_tuesday: '',
      day_wednesday: '',
      day_thursday:'',
      day_friday: '',
      day_saturday: '',
      day_sunday: '',
      afternoon_monday:'',
      afternoon_tuesday:'',
      afternoon_wednesday:'',
      afternoon_thursday:'',
      afternoon_friday: '',
      afternoon_saturday:'',
      afternoon_sunday:'',
      day_monday_value: '',
      day_tuesday_value: '',
      day_wednesday_value: '',
      day_thursday_value: '',
      day_friday_value: '',
      day_saturday_value: '',
      day_sunday_value: '',
      afternoon_monday_value:'',
      afternoon_tuesday_value:'',
      afternoon_wednesday_value:'',
      afternoon_thursday_value:'',
      afternoon_friday_value:'',
      afternoon_saturday_value:'',
      afternoon_sunday_value:'',

      attachment: null,
      loading: false,

   
    };


    
   
   

    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleSurname = this.handleSurname.bind(this);
    this.handleDOB = this.handleDOB.bind(this);
    this.handleAge = this.handleAge.bind(this);
    this.handleStreetAddress = this.handleStreetAddress.bind(this);
    this.handleSuburb = this.handleSuburb.bind(this);
    this.handlePostCode = this.handlePostCode.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobileNumber = this.handleMobileNumber.bind(this);
    this.handlehandlePreferredNumber = this.handlePreferredNumber.bind(this);
    this.ResidencyChecked = this.ResidencyChecked.bind(this);
    this.VisaChecked = this.VisaChecked.bind(this);
    this.handleVisaStatus = this.handleVisaStatus.bind(this);
    this.handleLengthStay = this.handleLengthStay.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleHoursWeek = this.handleHoursWeek.bind(this);

    // table

    this.handleDayMonday = this.handleDayMonday.bind(this);
    this.handleDayTuesday = this.handleDayTuesday.bind(this);
    this.handleDayWednesday = this.handleDayWednesday.bind(this);
    this.handleDayThursday = this.handleDayThursday.bind(this);
    this.handleDayFriday = this.handleDayFriday.bind(this);
    this.handleDaySaturday = this.handleDaySaturday.bind(this);
    this.handleDaySunday = this.handleDaySunday.bind(this);

    this.handleAfternoonMonday = this.handleAfternoonMonday.bind(this);
    this.handleAfternoonTuesday = this.handleAfternoonTuesday.bind(this);
    this.handleAfternoonWednesday = this.handleAfternoonWednesday.bind(this);
    this.handleAfternoonThursday = this.handleAfternoonThursday.bind(this);
    this.handleAfternoonFriday = this.handleAfternoonFriday.bind(this);
    this.handleAfternoonSaturday = this.handleAfternoonSaturday.bind(this);
    this.handleAfternoonSunday = this.handleAfternoonSunday.bind(this);

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.submitToGoogleSheets_careers = this.submitToGoogleSheets_careers.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // functions

  handleFirstName(e) {
    this.setState({ first_name: e.target.value });
  }
  handleSurname(e){
    this.setState({surname: e.target.value });
  }
  handleDOB = date => {
    this.setState({ d_o_b: date });
  }
  handleAge(e){
    this.setState({age: e.target.value});
  }
   handleStreetAddress(e){
    this.setState({ street_address: e.target.value  });
  }
  handlePostCode(e){

    this.setState({post_code: e.target.value });
    
  }
  handleSuburb(e){
   this.setState({suburb: e.target.value });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }
  
  handleMobileNumber(e) {
    this.setState({ mobile_number: e.target.value });
  }
  handlePreferredNumber(e){
    this.setState({ preferred_number: e.target.value });
  }



  VisaChecked(e){  
    this.setState({ 
      visa: true,
      visa_status: 'VISA',
      residency: false,
    });
  }
  ResidencyChecked(e){
     this.setState({
      visa: false,
      visa_status: 'Perrmanent Resident',
      residency: true
    });
  }
  handleVisaStatus(e) {
    this.setState({ your_status: e.target.value });
  }
   handleLengthStay(e){
     this.setState({ length_stay: e.target.value });
  }
  handleStartDate = date => {
     this.setState({ start_date: date });
  }
  handleHoursWeek(e){
     this.setState({ hours_week: e.target.value });
  }
  handleDayMonday(e){
      this.setState({ 
        day_monday_value: e.target.value,
        day_monday:  !this.state.day_monday,
      });
    }
 handleDayTuesday(e){
    this.setState({ 
      day_tuesday_value: e.target.value,
      day_tuesday:!this.state.day_tuesday
    });
  }
  handleDayWednesday(e){

    this.setState({ 
      day_wednesday_value: e.target.value,  
      day_wednesday: !this.state.day_wednesday
       });
  }
  handleDayThursday(e){
    this.setState({ 
      day_thursday_value: e.target.value, 
      day_thursday: !this.state.day_thursday
    });
  }
  handleDayFriday(e){
    this.setState({ 
      day_friday_value: e.target.value, 
      day_friday:!this.state.day_friday
    });
  }
  handleDaySaturday(e){
    this.setState({ 
      day_saturday_value: e.target.value,
      day_saturday:!this.state.day_saturday
       });
  }
  handleDaySunday(e){
    this.setState({ 
      day_sunday_value: e.target.value,
      day_sunday: !this.state.day_sunday
      });
  }

  handleAfternoonMonday(e){
    this.setState({ 
      afternoon_monday_value: e.target.value,
      afternoon_monday:!this.state.afternoon_monday
    });
  }
  handleAfternoonTuesday(e){
    this.setState({ 
      afternoon_tuesday_value: e.target.value, 
      afternoon_tuesday: !this.state.afternoon_tuesday
    });
  }
  handleAfternoonWednesday(e){
    this.setState({ 
      afternoon_wednesday_value: e.target.value,
      afternoon_wednesday: !this.state.afternoon_wednesday
    });
  }
  handleAfternoonThursday(e){
    this.setState({ 
      afternoon_thursday_value: e.target.value,
      afternoon_thursday: !this.state.afternoon_thursday
    });
  }
  handleAfternoonFriday(e){
    this.setState({ 
      afternoon_friday_value: e.target.value,
      afternoon_friday:!this.state.afternoon_friday

    });
  }
  handleAfternoonSaturday(e){
    this.setState({ 
      afternoon_saturday_value: e.target.value,
      afternoon_saturday:!this.state.afternoon_saturday
    });
  }
  handleAfternoonSunday(e){
    this.setState({ 
      afternoon_sunday_value: e.target.value,
      afternoon_sunday: !this.state.afternoon_sunday
    });
  }

  handleFileUpload(e){
    let fileValue = e.target.value.replace("C:\\fakepath\\", " ");

    if (e.target.files.length > 0) {
      this.setState({ attachment: e.target.files[0] });
    }
  }

  // google sheet
  submitToGoogleSheets_careers() {

     const currentDateTime =  new Date().toLocaleString().replace(",","").replace(/:.. /," ");

      const FORM_GOOGLE_API_URL = 'https://script.google.com/macros/s/AKfycbxPQ1ayTB4rTTL0FV1CqnKCC3rf8NL6YnA20mvkTSU5uNkljds/exec'
    + '?first_name=' + encodeURIComponent(this.state.first_name)
    + '&surname=' + encodeURIComponent(this.state.surname)
    + '&d_o_b=' + encodeURIComponent(this.state.d_o_b)
    + '&age=' + encodeURIComponent(this.state.age)
    + '&street_addresss=' + encodeURIComponent(this.state.street_address)
    + '&post_code=' + encodeURIComponent(this.state.post_code)
    + '&suburb=' + encodeURIComponent(this.state.suburb)
    + '&email=' + encodeURIComponent(this.state.email) 
    + '&mobile_number=' + encodeURIComponent(this.state.mobile_number)
    + '&preferred_number=' + encodeURIComponent(this.state.preferred_number)
    + '&residency_type=' + encodeURIComponent(this.state.visa_status)
    + '&your_status=' + encodeURIComponent(this.state.your_status)
    + '&length_stay=' + encodeURIComponent(this.state.length_stay)
    + '&start_date=' + encodeURIComponent(this.state.start_date)
    + '&hours_week=' + encodeURIComponent(this.state.hours_week)

    + '&day_monday=' + encodeURIComponent(this.state.day_monday_value)
    + '&day_tuesday=' + encodeURIComponent(this.state.day_tuesday_value)
    + '&day_wednesday=' + encodeURIComponent(this.state.day_wednesday_value)
    + '&day_thursday=' + encodeURIComponent(this.state.day_thursday_value)
    + '&day_friday=' + encodeURIComponent(this.state.day_friday_value)
    + '&day_saturday=' + encodeURIComponent(this.state.day_saturday_value)
    + '&day_sunday=' + encodeURIComponent(this.state.day_sunday_value)

    + '&afternoon_monday=' + encodeURIComponent(this.state.afternoon_monday_value)
    + '&afternoon_tuesday=' + encodeURIComponent(this.state.afternoon_tuesday_value)
    + '&afternoon_wednesday=' + encodeURIComponent(this.state.afternoon_wednesday_value)
    + '&afternoon_thursday=' + encodeURIComponent(this.state.afternoon_thursday_value)
    + '&afternoon_friday=' + encodeURIComponent(this.state.afternoon_friday_value)
    + '&afternoon_saturday=' + encodeURIComponent(this.state.afternoon_saturday_value)
    + '&afternoon_sunday=' + encodeURIComponent(this.state.afternoon_sunday_value)
    + '&file_upload=' + encodeURIComponent(this.state.attachment ? this.state.attachment.name : 'none')

    + '&date_time=' + currentDateTime

    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', FORM_GOOGLE_API_URL);
    httpRequest.send();
    console.log(FORM_GOOGLE_API_URL);
  }



  // handle form submission
   handleSubmit(event) { 

    event.preventDefault();
    event.currentTarget.reset();

    // Submit to google.
    this.submitToGoogleSheets_careers();

    // Submit to lambda.
    const form = {
      'First Name': this.state.first_name,
      'Last Name': this.state.surname,
      'DOB': this.state.d_o_b,
      'Age': this.state.age,
      'Street Address': this.state.street_address,
      'Post Code': this.state.post_code,
      'Suburb' : this.state.suburb,
      'Email': this.state.email,
      'Mobile Number': this.state.mobile_number,
      'Preferred Number': this.state.preferred_number,
      'Residency Type': this.state.visa_status,
      'Visa Status': this.state.your_status,
      'Length of Stay': this.state.length_stay,
      'Able start date': this.state.start_date.toLocaleString('en-AU', { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' }),
      'Hours per week': this.state.hours_week,
      'Monday AM': this.state.day_monday_value,
      'Tuesday AM': this.state.day_tuesday_value,
      'Wednesday AM': this.state.day_wednesday_value,
      'Thursday AM': this.state.day_thursday_value,
      'Friday AM': this.state.day_friday_value,
      'Saturday AM': this.state.day_saturday_value,
      'Sunday AM': this.state.day_sunday_value,
      'Monday PM': this.state.afternoon_monday_value,
      'Tuesday PM': this.state.afternoon_tuesday_value,
      'Wednesday PM': this.state.afternoon_wednesday_value,
      'Thursday PM': this.state.afternoon_thursday_value,
      'Friday PM': this.state.afternoon_friday_value,
      'Saturday PM': this.state.afternoon_saturday_value,
      'Sunday PM': this.state.afternoon_sunday_value,
    };

    this.setState({ loading: true });
    submitCareers(form, this.state.attachment)
      .then(res => {
        this.setState({ 
          status: 'success',
          loading: false
           });
      })
      .catch(err => {
        console.log(err.message);
        this.setState({ status: 'error' });
      });
  }


  render(){

    const { status } = this.state;



  return (


     <div className="contact-page">
     <ComponentHeader/>
       <Helmet>
            <title>Careers</title>
            <link rel="canonical" href="https://www.twinfin/careers" />
            <meta name="description" content="We want to change the way people think about modern seafood and deliver an affordable, fun alternative. Contact us to find out more" />
        </Helmet>
         <div className="contact-wrapper">
          <div className="heading">
            <h2 style={{marginBottom:'-15px'}}>Careers</h2>
            <p className="sub-info">APPLICANT INFORMATION</p>
          </div>
          <div className="content contact-wrapper">
            <form onSubmit={this.handleSubmit}>
              <div className="form-double-row">
                <div>
                  <label for="first_name">First Name:</label>
                  <input 
                  type="text" 
                  id="first_name" 
                  name="first_name"
                  onChange={this.handleFirstName}
                  required
                  />
                </div>
                <div>
                  <label for="surname">Surname:</label>
                 <input 
                 type="text" 
                 id="surname" 
                 name="surname"
                 onChange={this.handleSurname}
                 required
                 />
                </div>
              </div>
              <div className="form-double-row">
                <div>
                <label for="requested-date">Date of Birth:</label>
                  <DatePicker 
                  type="date" 
                  id="d_o_b" 
                  name="d_o_b"
                  selected={this.state.d_o_b}
                  onChange={this.handleDOB}
                  required
                  />
                </div>
                <div>
                 <label for="estimated-numbers">Age:</label>
                 <input 
                 type="text" 
                 id="age" 
                 name="age"
                 onChange={this.handleAge}
                 required
                 />
                </div>
              </div>
              <div className="form-double-row">
                <div>
                <label for="street_address">Street Address:</label>
                  <input 
                  type="text" 
                  id="street_address" 
                  name="street_address"
                  onChange={this.handleStreetAddress}

                  />
                </div>
                <div>
                 <label for="post_code">Post Code:</label>
                 <input 
                 type="text" 
                 id="post_code" 
                 name="post_code"
                 onChange={this.handlePostCode}
                 />
                </div>
              </div>
              <div className="form-row">
                <label for="estimated-numbers">Suburb:</label>
                 <input 
                 type="text" 
                 id="suburb" 
                 name="suburb"
                 onChange={this.handleSuburb}
                 required
                 />
              </div>
              <div className="form-row">
                <label for="estimated-numbers">Email:</label>
                 <input 
                 type="email" 
                 id="email" 
                 name="email"
                 onChange={this.handleEmail}
                 required
                 />
              </div>
               <div className="form-double-row">
                <div>
                <label for="mobile_number">Mobile Number:</label>
                  <input 
                  type="text" 
                  id="mobile_number" 
                  name="mobile_number"
                  onChange={this.handleMobileNumber}
                  required
                  />
                </div>
                <div>
                 <label for="preferred_number">Preferred Number:</label>
                 <input 
                 type="text" 
                 id="preferred_number" 
                 name="preferred_number"
                 onChange={this.handlePreferredNumber.bind(this)}
                 />
                </div>
              </div>

              <div className="residency-wrapper" style={{marginTop:'15px'}}>
                <div>
                  <label for="residency">Permanent Resident:</label>
                </div>
                <div  
                  className={this.state.residency ? 'active' : 'non-active'}
                  >
                    <input
                    type="radio"
                    name="residency_type"
                    className="radio"
                    value={this.state.residency_type}
                    onClick={this.ResidencyChecked}
                    />
                   <span 
                   className="checkmark"
                   >
                    <img src={Tick} alt="yes"/>
                   </span>
                  </div>
                <div>
                  <label for="visa">VISA:</label>
                </div>
                  <div 
                    className={this.state.visa ? 'active' : 'non-active'}
                    >
                  <input
                  type="radio"
                  name="residency_type"
                  className="radio"
                  value={this.state.residency_type}
                  onClick={this.VisaChecked}
                  />
                   <span 
                    className="checkmark"
                   >
                    <img src={Tick} alt="yes"/>
                 </span>
                </div>
              </div>
             <div className="form-row">
                <label for="visa_status">IF VISA, WHAT IS YOUR STATUS</label>
                 <input 
                 type="text" 
                 id="your_status" 
                 name="your_status"
                 onChange={this.handleVisaStatus}
                 />
              </div>
               <div className="form-row">
                <label for="length_stay">LENGTH OF STAY IN PERTH:</label>
                 <input 
                 type="text" 
                 id="length_stay" 
                 name="length_stay"
                 onChange={this.handleLengthStay}
                 />
              </div>
               <div className="form-double-row">
                <div>
                <label for="start_date">WHEN WILL YOU BE ABLE TO START:</label>
                  <DatePicker 
                  type="date" 
                  id="start_date" 
                  name="start_date"
                  selected={this.state.start_date}
                  onChange={this.handleStartDate}

                  />
                </div>
                <div>
                 <label for="hours_week">HOURS PER WEEK:</label>
                 <input 
                 type="text" 
                 id="hours_week" 
                 name="hours_week"
                 onChange={this.handleHoursWeek}
                 />
                </div>
              </div>
              <div className="form-row">
              <label>PLEASE TICK YOUR SHIFT PREFERENCES BELOW:</label>
              <div className="calendar-wrapper">
                <div className="row-one row">
                  <div>

                  </div>
                  <div>
                    MON
                  </div>
                  <div>
                    TUES
                  </div>
                  <div>
                    WED
                  </div>
                  <div>
                    THU
                  </div>
                  <div>
                    FRI
                  </div>
                  <div>
                    SAT
                  </div>
                  <div>
                    SUN
                  </div>
                </div>
                 <div className="row-two row">
                  <div>
                    AM
                  </div>
                  <div
                    className={this.state.day_monday ? 'active' : 'non-active'}
                    
                    >
                    <input
                    type="checkbox"
                    name="day_monday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDayMonday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.day_tuesday ? 'active' : 'non-active'}
                     >
                  
                     <input
                    type="checkbox"
                    name="day_tuesday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDayTuesday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.day_wednesday ? 'active' : 'non-active'}
                   >
                   <input
                    type="checkbox"
                    name="day_wednesday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDayWednesday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.day_thursday ? 'active' : 'non-active'}
                    >
                   <input
                    type="checkbox"
                    name="day_thursday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDayThursday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.day_friday ? 'active' : 'non-active'}
                   
                    >
                  
                    <input
                    type="checkbox"
                    name="day_friday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDayFriday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                 <div
                    className={this.state.day_saturday ? 'active' : 'non-active'}
                   
                    >
                  
                    <input
                    type="checkbox"
                    name="day_saturday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDaySaturday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.day_sunday ? 'active' : 'non-active'}
                    
                    >
                  
                     <input
                    type="checkbox"
                    name="day_sunday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleDaySunday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                </div>
                 <div className="row-three row">
                  <div>
                    PM
                  </div>
                  <div
                    className={this.state.afternoon_monday ? 'active' : 'non-active'}
                    
                    >
                  
                     <input
                    type="checkbox"
                    name="afternoon_monday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonMonday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_tuesday ? 'active' : 'non-active'}
                    
                    >
                  
                     <input
                    type="checkbox"
                    name="afternoon_tuesday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonTuesday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_wednesday ? 'active' : 'non-active'}
                    
                    >
                  
                     <input
                    type="checkbox"
                    name="afternoon_wednesday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonWednesday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_thursday ? 'active' : 'non-active'}
                    
                    >
                  
                      <input
                    type="checkbox"
                    name="afternoon_thursday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonThursday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_friday ? 'active' : 'non-active'}
                    
                    >
               
                     <input
                    type="checkbox"
                    name="afternoon_friday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonFriday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_saturday ? 'active' : 'non-active'}
                   
                    >
               
                    <input
                    type="checkbox"
                    name="afternoon_saturday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonSaturday}
                    />
                     <span 
                      className="checkmark"
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                  <div
                    className={this.state.afternoon_sunday ? 'active' : 'non-active'}
                   
                    >
                 
                     <input
                    type="checkbox"
                    name="afternoon_sunday"
                    className="checkbox-table"
                    value="yes"
                    onClick={this.handleAfternoonSunday}
                    />
                     <span 
                      className="checkmark"
                      style={{borderBottomRightRadius:'20px'}}
                     ></span>
                    <img src={Tick} alt="yes"/>
                  </div>
                </div>
              </div>
              </div>
               <div className="form-double-row file-upload-and-submit">
                <div>
                  <input
                  type="file" 
                  id="file_upload" 
                  name="file_upload"
                  onChange={this.handleFileUpload}

                  />
                  <span>{this.state.attachment ? this.state.attachment.name : 'UPLOAD YOUR CV'}</span>
                </div>
                <div>
                <input 
                type="submit" 
                id="submit" 
                className="submit-button"

                />
           
                </div>
              </div>
            </form>
            <div className={this.state.loading ? 'lds-ripple active' : 'lds-ripple non-active'} ><div></div><div></div></div>
              {
                   status === 'success' &&
                   <div className="status">
                     <div className="flexMargin">
                       <p>Thank you for contacting us - someone from our team will get back to you shortly!</p>
                     </div>
                   </div>
                 }
          </div>
        </div>
      </div>
    );
  }
}