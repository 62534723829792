/*
  ComponentHeader.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentHeader.scss';
import HamburgerMenu from 'react-hamburger-menu';
// images

import Logo from '../../Media/svg/TWINFIN-LOGO.svg';
import Instagram from '../../Media/svg/INSTAGRAM.svg';
import Facebook from '../../Media/svg/FACEBOOK.svg';
import CloseMenu from '../../Media/png/close-menu.png';
import OpenMenu from '../../Media/png/open-menu.png';
import cross from '../../Media/png/close-orange.png';
import deliveroo from '../../Media/png/deliveroo-box.png';
import menulog from '../../Media/png/menu-log-small.png';
import uberLogo from '../../Media/png/uber-eats.png';


export default class ComponentHeader extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {
    
      active_one: false,
      active_two: false,
      active_three: false,
      active_four: false,
      active_five: false,
      active_six: false,
      active_seven:false,
      open: '',
      menu_mobile: '',
      mobile_off: '',
      modal: ''
    };
  }

   componentDidMount() {
    window.scrollTo(0, 0);

  }

  activateModal(){
    this.setState({
      modal: !this.state.modal,
    })
  }

  activateLink_one(){
    this.setState({ 
      active_one: true,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });

  }
  activateLink_two(){
     this.setState({ 
      active_one: false,
      active_two: true,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }
   activateLink_three(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: true, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }

    activateLink_four(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: true, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }

    activateLink_five(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: true, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 


    });
  }

    activateLink_six(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: true, 
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile

    });
  }

   activateLink_seven(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false, 
      active_seven:true,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile

    });
  }



handleClick() {
  this.setState({
    open: !this.state.open,
    menu_mobile: !this.state.menu_mobile
  });

}

 

  render(){

   // const {
      
   // } = this.props;


  //const modalClass = this.state.modalShow ? 'modal modal-off' : 'modal modal-on';

  return (


     <div className="component-header">

        <div className="logo-wrapper">
          <Link 
          to="/"
          onClick={this.activateLink_four.bind(this)}
          >
            <img src={Logo} alt="Twin Fin"/>
            <h2>Restaurant & Bar</h2>
          </Link>
        </div>
        <div className="navigation-menu">
        <div className="hamburger-wrapper">
        <div 
          className="mobile-wrapper-show"
          onClick={this.handleClick.bind(this)}
          className={this.state.open ? 'mobile-wrapper-show mobile-on' : 'mobile-wrapper-show mobile-off'}
          id="mobile-wrapper-show"
          >
         {this.state.menu_mobile ? <img src={CloseMenu} style={{width:'40px'}}/> : <img src={OpenMenu} style={{width:'40px'}}/> }
        </div>
         
          </div>
          <span className={this.state.open ? 'mobile-on' : 'mobile-off'}>
            <Link 
            to="/about" 
            onClick={this.activateLink_one.bind(this)}
            className={this.state.active_one ? 'active weight-bold' : 'not-active weight-bold'}
            >

             About
              

            </Link>
            <Link 
            to="/menu/"
            onClick={this.activateLink_two.bind(this)}
            className={this.state.active_two ? 'active weight-bold' : 'not-active weight-bold'}

            >

             Menu

            </Link>

            
            <Link 
            to="/school-lunch/"
            onClick={this.activateLink_three.bind(this)}
            className={this.state.active_three ? 'active weight-bold' : 'not-active weight-bold'}

            >

            School  Lunch

            </Link>

             <Link 
            to="/group-bookings/"
            onClick={this.activateLink_four.bind(this)}
            className={this.state.active_four ? 'active weight-bold' : 'not-active weight-bold'}

            >

            Group Bookings and Reservations

            </Link>

             <Link 
            to="/careers/"
            onClick={this.activateLink_five.bind(this)}
            className={this.state.active_five ? 'active weight-bold' : 'not-active weight-bold'}

            >
            
            Careers

            </Link>
      
            <Link 
            to="/contact/"
            onClick={this.activateLink_six.bind(this)}
            className={this.state.active_six ? 'active weight-bold' : 'not-active weight-bold'}
            >
               Contact
            </Link>
           {/* <a 
            href="https://www.ubereats.com/au/perth/food-delivery/twin-fin-fish-%26-chips-fremantle/p9k8W65cRyOQjAdxDTQmJw"
            onClick={this.activateLink_seven.bind(this)}
            className={this.state.active_seven ? 'active weight-bold' : 'not-active weight-bold'}
            target="_blank"
            rel="noreferrer"
            >
               Order Online
            </a> */}
            </span>
        </div>
        <div className="socials-wrapper">
          <a className="twinfin-takeaway-wrap" href="https://twinfin.yourorder.io/#!/home" rel="noopener noreferrer" target="_blank">
            <p>TWIN FIN<br></br> <strong>DINE IN OR TABLE ORDERING</strong><br></br>(fast & easy!)</p>
          </a> 
          <a className="twinfin-takeaway-wrap" href="https://twinfin.yourorder.io/#!/home" rel="noopener noreferrer" target="_blank">
            <p>TWIN FIN <br></br> <strong>TAKEAWAY OR PICK-UP</strong><br></br>(dine-in prices)</p>
          </a> 
          <p className="delivery-wrap"><span>TWIN FIN DELIVERY</span><br></br> 
              <a href="https://www.ubereats.com/au/perth/food-delivery/twin-fin-fish-%26-chips-fremantle/p9k8W65cRyOQjAdxDTQmJw" rel="noopener noreferrer" target="_blank">
              <img src={uberLogo} alt="ubereats" style={{width:'50px',marginRight:'5px'}}/>
            </a> 
            {/*<a href="https://deliveroo.com.au/menu/perth/fremantle/twin-fin-fish-and-chips" rel="noopener noreferrer" target="_blank">
              <img src={deliveroo} alt="ubereats" style={{width:'50px',borderRadius:'15px',marginLeft:'5px'}}/>
            </a> 
             <a href="https://www.menulog.com.au/restaurants-twin-fin-fish-and-chips/menu" rel="noopener noreferrer" target="_blank">
              <img src={menulog} alt="ubereats" style={{width:'50px',borderRadius:'15px',marginLeft:'10px'}}/>
            </a>*/}
          </p>
          {/* <a href="https://www.ubereats.com/au/perth/food-delivery/twin-fin-fish-%26-chips-fremantle/p9k8W65cRyOQjAdxDTQmJw" rel="noopener noreferrer" target="_blank">
          </a>  */}

          {/* <a href="https://www.instagram.com/twinfin_au/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="instagram"/>
          </a>
          <a href="https://www.ubereats.com/au/perth/food-delivery/twin-fin-fish-%26-chips-fremantle/p9k8W65cRyOQjAdxDTQmJw" rel="noopener noreferrer" target="_blank">
            <img src={uberLogo} alt="ubereats" style={{width:'65px',marginRight:'5px'}}/>
          </a> 
          <a href="https://deliveroo.com.au/menu/perth/fremantle/twin-fin-fish-and-chips" rel="noopener noreferrer" target="_blank">
            <img src={deliveroo} alt="ubereats" style={{width:'65px',borderRadius:'15px',marginLeft:'5px'}}/>
          </a> 
          <a href="https://www.menulog.com.au/restaurants-twin-fin-fish-and-chips/menu" rel="noopener noreferrer" target="_blank">
            <img src={menulog} alt="ubereats" style={{width:'65px',borderRadius:'15px',marginLeft:'10px'}}/>
          </a>


          <a href="https://www.facebook.com/TwinFinAU/" target="_blank" rel="noreferrer">
            <img src={Facebook} alt="facebook"/>
          </a> */}
        </div>
      </div>
    );
  }
}