/*
  AboutPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';


// style
import './ComponentUberEats.scss';
import UberLogo from '../../Media/png/uber-eats.png';
import deliveroo from '../../Media/jpeg/deliveroo-logo.jpg';
import menulog from '../../Media/png/menu-log-main.png'


export default class ComponentUberEats extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      active: false,
  
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({

      active: false,
     

    });
  
  
  }
  render(){
    const {
      marginTop,
      paddingBottom
    } = this.props;

  return (
    <div className="menu-pdf-page">
        <div className="menu-pdf-wrapper" style={{paddingBottom:paddingBottom,marginTop:marginTop}}>
         <a href="https://www.ubereats.com/perth/food-delivery/twin-fin-fish-%2526-chips-fremantle/p9k8W65cRyOQjAdxDTQmJw" rel="noopener noreferrer" target="_blank">
          <img src={UberLogo} alt="ubereats" class="ubereats"/>
          </a>
          <a href="https://deliveroo.com.au/menu/perth/fremantle/twin-fin-fish-and-chips" target="_blank">
            <img src={deliveroo} alt="deliveroo" target="_blank" class="deliveroo"/>
          </a>
          <a href="https://www.menulog.com.au/restaurants-twin-fin-fish-and-chips/menu" target="_blank">
            <img src={menulog} alt="deliveroo" target="_blank" class="menulogo"/>
          </a>

          <h3>Menu below is for in-store or phone orders only</h3>
        </div>
    </div>
    );
  }
}