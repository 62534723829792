/*
  AboutPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import './AboutPage.scss';

// images

import Heading from '../../Media/svg/TALES_FROM_THE_DEEP.svg';
import AboutContent from '../../Media/svg/STORY.svg';
import ComponentHeader from './../ComponentHeader';
import ComponentPop from './../ComponentPop';

export default class AboutPage extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      active: false,
  
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({

      active: false,
     

    });
  
  
  }
  render(){


  return (
    <div className="about-page">
    <ComponentHeader/>
      <Helmet>
          <title>About</title>
          <link rel="canonical" href="https://www.twinfin/about" />
          <meta name="description" content="Twin Fin is a fast-casual fish and chips restaurant & bar that breaks the rules - but doesn’t steer too far from tradition." />
      </Helmet>
        <div className="about-wrapper">
          <div className="heading">
            <h2>Background:</h2>
          </div>
          <div className="content">
            <p>Twin Fin is a fast-casual fish and chips restaurant & bar that breaks the rules - but doesn’t steer too far from tradition. We want to change the way people think about modern seafood and deliver an affordable, fun alternative. In an age where sustainability and “paddock-to-plate” are dominating global food trends, we’re happy to keep it simple and provide tasty food to share in a warm atmosphere, welcomed by locals and visitors alike.</p>
           <h2>Tales from<br/> the Deep</h2>
           <p>We were born in the water. Days spent wrinkling out amongst the waves, sleeping on hot sand and hanging out at the local fish and chips restaurant & bar. These were the days of deep frying in the relentless summer sun, salt in the eyes, vinegar on stingers.</p>
           <p>So we created a place for everyone to run wild, have fun and feel welcome. Twin Fin is a place without pretensions, serving humble and tasty fish and chips, jugs of beer and ice creams. We’re a modern, fun-loving spot where people can come with families, buddies and workmates to eat great and affordable fish and chips served with a good side of swell times.</p>
           <p>You know, the way fish and chips ought to be. </p>
          </div>
        </div>
    </div>
    );
  }
}