/*
  AboutPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import './MenuPdf.scss';

// images

import Heading from '../../Media/svg/TALES_FROM_THE_DEEP.svg';
import AboutContent from '../../Media/svg/STORY.svg';

import imageMenu from '../../Media/png/twin-fin-menu-2024.png';
import bevMenu1 from '../../Media/png/twin-fin-bar-menu-2024.png';

export default class MenuPdf extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      active: false,
      menu1: true,
      menu2: false,
  
    };

     
    // Binding this keyword
    this.handleClick = this.handleClick.bind(this)
    this.handleClick2 = this.handleClick2.bind(this)
  }

  handleClick(){
   
    // Changing state
    this.setState({menu1 : true,menu2 : false})
  }
  handleClick2(){
   
    // Changing state
    this.setState({menu1 : false,menu2 : true})

  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({

      active: false,

     

    });
  
  
  }




  render(){

  const {
    marginTop
  } = this.props

  return (
    <div className="menu-pdf-page">
        <h3>Prices below are for dine-in only</h3>
        <div className="menu-toggle">
          <button className="menu-toggle1" onClick={this.handleClick}>Food Menu</button>
          <button className="menu-toggle2" onClick={this.handleClick2}>Bar Menu</button>
        </div>

        {this.state.menu1==true ? 
        <div className="menu-pdf-wrapper food-menu" style={{paddingBottom:'100px',marginTop:marginTop}}>
          <img src={imageMenu} alt="Twin Fin Menu" className="Menu_pdf"/>
        </div>
        :""}
        {this.state.menu2==true ? 
        <div className="menu-pdf-wrapper bev-menu" style={{paddingBottom:'100px',marginTop:marginTop}}>
          <img src={bevMenu1} alt="Twin Fin Beverage Menu" className="Menu_pdf"/>
        </div>
        :
        <div className="menu-pdf-wrapper bev-menu hidden" style={{paddingBottom:'100px',marginTop:marginTop}}>
          <img src={bevMenu1} alt="Twin Fin Beverage Menu" className="Menu_pdf"/>
        </div>}
    </div>
    );
  }
}