/*
  ContactPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {Helmet} from "react-helmet";

import '../ContactPage/ContactPage.scss';
import "react-datepicker/dist/react-datepicker.css";


// images

import Heading from '../../Media/svg/CONTACT_US.svg';


export default class ContactPage extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {


    };


  }
  componentDidMount() {
    window.scrollTo(0, 0);

  }




 

  render(){


  return (


     <div className="contact-page">
       <Helmet>
            <title>Contact - Twin Fn</title>
            <link rel="canonical" href="https://www.twinfin/404" />
            <meta name="description" content="Page not found" />
        </Helmet>
         <div className="contact-wrapper" style={{marginBottom:'100px'}}>
          <div className="heading">
            <h2 style={{marginBottom:'-15px'}}>404</h2>
            <p className="sub-info">Sorry this page does not exist</p>
          </div>
         
        </div>
      </div>
    );
  }
}