/*
  CovidPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {Helmet} from "react-helmet";
import Instagram from '../../Media/svg/INSTAGRAM.svg';
import Facebook from '../../Media/svg/FACEBOOK.svg';

import './CovidPage.scss';
import "react-datepicker/dist/react-datepicker.css";


// images

import Heading from '../../Media/svg/CONTACT_US.svg';


export default class CovidPage extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {

      full_name: '',
      phone: '',
      email: '',
      status: '',
      menu_mobile: ''

   
    };


    this.submitToGoogleSheets = this.submitToGoogleSheets.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleFullName = this.handleFullName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
  
  }
  componentDidMount() {
    window.scrollTo(0, 0);

   this.setState({ menu_mobile: 'mobile-off' });
  }

  // functions

  handleFullName(e) {
    this.setState({ full_name: e.target.value });
  }
  handlePhone(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }



  // google sheet
  submitToGoogleSheets() {

     const currentDateTime =  new Date().toLocaleString();
     const location = window.location.href;

      const FORM_GOOGLE_API_URL = 'https://script.google.com/macros/s/AKfycbydUbrK8Etv3dl2Kzx3KQ5Tsu6SR0uQb6aMNkavh8SUl1niYNUh/exec'
    + '?full_name=' + encodeURIComponent(this.state.full_name)
    + '&phone=' + encodeURIComponent(this.state.phone)
    + '&email=' + encodeURIComponent(this.state.email)
    + '&location=' + location
    + '&date_time=' + currentDateTime

    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', FORM_GOOGLE_API_URL);
    httpRequest.send();
    console.log(FORM_GOOGLE_API_URL);
  }

  // handle form submission
   handleSubmit(event) { 

    event.preventDefault();
    event.currentTarget.reset();

    this.submitToGoogleSheets();


    console.log('submitted');

    this.setState({
      status: 'success'
    });


  }


 

  render(){

  const { status } = this.state;

  return (


     <div className="covid-page">
       <Helmet>
            <title>Covid - Twin Fn</title>
            <link rel="canonical" href="https://www.twinfin/covid-thanks" />
            <meta name="description" content="please fill out your details below for the state government for COVID-19 Protocols for phase 2 in WA" />
        </Helmet>
         <div className="contact-wrapper">
          <div className="heading">
            <h2 style={{marginBottom:'-15px'}}>Thanks for dining with us</h2>
            <p className="sub-info" style={{fontSize:'1.75rem'}}>Please fill out your details below<br/> for WA COVID Safety Plan, Public Health Records</p>
            <p className="sub-info">It is a requirement for businesses to maintain attendance records of patrons. This will assist Public Health with contact tracing in the event of a positive COVID-19 case in our restaurant. We would like to assure the safety and wellbeing of you, our customers, staff and local community are at the forefront and we appreciate your co-operation during this time.</p>

          </div>
          <div className="content contact-wrapper">
            <form onSubmit={this.handleSubmit}>
              <div className="form-double-row">
                <div>
                  <label for="full-name">Full Name*</label>
                  <input 
                  type="text" 
                  id="full_name" 
                  name="full_name"
                  onChange={this.handleFullName}
                  required
                  />
                </div>
                <div>
                  <label for="phone">Phone*</label>
                 <input 
                 type="text" 
                 id="phone" 
                 name="phone"
                 onChange={this.handlePhone}
                 required
                 />
                </div>
              </div>
               <div className="form-double-row">
                <div>
                  <label>Email*</label>
                  <input 
                  type="email" 
                  id="email" 
                  name="email"
                  onChange={this.handleEmail}
                  required
                  />
                </div>
                <div className="form-row landing-submit">
                  <input 
                  type="submit" 
                  id="submit" 
                  className="submit-button"
                  />
                </div>
              </div>
      
             
            </form>
              {
                   status === 'success' &&
                   <div className="status">
                     <div className="flexMargin">
                       <p>Thank you for contacting us - someone from our team will get back to you shortly!</p>
                     </div>
                   </div>
                 }
          <p clasName="please-note" style={{fontSize:'1rem',color:'#1675A9',fontWeight:'lighter',letterSpacing:'1px',paddingTop:'15px',borderTop:'1px solid rgba(0,0,0,0.1)'}}>Please note; ALL personal information is not used for marketing purpose and ONLY used for the purpose of COVID-19 Safe Plan for the WA state government.</p>
          <p className="please-note" style={{fontSize:'1rem',color:'#1675A9',fontWeight:'lighter',letterSpacing:'1px',paddingTop:'15px',marginTop:'50px'}}>Enjoyed your meal?</p>
          <a href="https://www.google.com/search?ei=ZFjDXv_iJYnDz7sPiNitgA8&q=twin+fin+fish+n+chips&oq=twin+fin+fish+n+chips&gs_lcp=CgZwc3ktYWIQAzICCAAyBggAEBYQHjIGCAAQFhAeOgQIABBHOgQIABBDUNQYWNYnYKQoaABwAXgAgAHmA4gByB2SAQcyLTQuNS4ymAEAoAEBqgEHZ3dzLXdpeg&sclient=psy-ab&ved=0ahUKEwj_pJXcg7_pAhWJ4XMBHQhsC_AQ4dUDCAw&uact=5#lrd=0x2a32a1f7e01269bb:0x9247212756a82535,1,,," target="_blank" rel="noreferrer" className="leave-review-link"> Leave us a review</a>
          <div class="social-links">
          <a href="https://www.instagram.com/twinfin_au/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="instagram"/>
          </a>
          <a href="https://www.facebook.com/TwinFinAU/" target="_blank" rel="noreferrer">
            <img src={Facebook} alt="facebook"/>
          </a>
          </div>
          </div>
        </div>
      </div>
    );
  }
}