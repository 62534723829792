/*
  ComponentFooter.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentSectionOneHome.scss';
/*import Tilt from 'react-tilt'*/

// images

import Logo from '../../Media/svg/TWINFIN-LOGO.svg';
import Location from '../../Media/svg/LOCATION.svg';
import Stayhooked from '../../Media/svg/STAY.svg';

// backgrounds 

import background_one from '../../Media/png/about-v2.png';
import background_two from '../../Media/png/contact-v2.png';
import background_three from '../../Media/png/menu-v2.png';
import background_four from '../../Media/png/reviews-v2.png';


export default class ComponentSectionOneHome extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
    render(){


  return (
    <div className="component-section-one-home">
      <div className="fish-chips">
          <div>

          </div>
      </div>
      <div className="link-section-wrapper">
        <Link to="/about/" className="about weight-bold" style ={ { backgroundImage: "url("+background_one+")" } }>
          <span className="filter"></span>
          <span className="menu-item">ABOUT</span>
        </Link>
        <Link to="/menu/" className="menu weight-bold" style={ { backgroundImage: "url("+background_two+")" } }>
          <span className="filter"></span>
          <span className="menu-item">MENU</span>
        </Link>
        <a href="https://www.google.com/search?q=twin+fin&oq=twin+fi&aqs=chrome.0.69i59j69i57j0l3j69i61j69i60j69i61.1142j0j7&sourceid=chrome&ie=UTF-8#lrd=0x2a32a1f7e01269bb:0x9247212756a82535,1,,," target="_blank" rel="noreferrer" className="facebook weight-bold" style ={ { backgroundImage: "url("+background_three+")" } }>
          <span className="filter"></span>
          <span className="menu-item">REVIEWS</span>
        </a>
        <Link to="/contact/" className="contact weight-bold" style ={ { backgroundImage: "url("+background_four+")" } }>
          <span className="filter"></span>
          <span className="menu-item">CONTACT</span>
        </Link>
      </div>
    </div>
    );
  }
}