/*
  ContactPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {Helmet} from "react-helmet";
import ComponentHeader from './../ComponentHeader';
import ComponentPop from './../ComponentPop';
import '../ContactPage/ContactPage.scss';
import "react-datepicker/dist/react-datepicker.css";


// images

import Heading from '../../Media/svg/CONTACT_US.svg';


export default class SchoolLunch extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {

      full_name: '',
      phone: '',
      email: '',
      requested_time: '',
      requested_dates: '',
      group_name: '',
      booking_type: '',
      estimated_numbers: '',
      additional_questions: '',
      dietary_requirements: '',
      status: ''
   
    };


    this.submitToGoogleSheets_school_lunch = this.submitToGoogleSheets_school_lunch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleFullName = this.handleFullName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleGroupName = this.handleGroupName.bind(this);
    this.handleBookingType = this.handleBookingType.bind(this);
    this.handleRequestedTime = this.handleRequestedTime.bind(this);
    this.handleRequestedDates = this.handleRequestedDates.bind(this);
    this.handleEstimatedNumbers = this.handleEstimatedNumbers.bind(this);
    this.handleAdditionalQuestions = this.handleAdditionalQuestions.bind(this);
    this.handleDietaryRequirements = this.handleDietaryRequirements.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // functions

  handleFullName(e) {
    this.setState({ full_name: e.target.value });
  }
  handlePhone(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleRequestedDates = date => {
    this.setState({ requested_dates: date });
  }
  handleRequestedTime(e) {
    this.setState({ requested_time: e.target.value });
  }
 handleGroupName(e) {
    this.setState({ group_name: e.target.value });
  }
  handleBookingType(e) {
    this.setState({ booking_type: e.target.value });
  }
  handleEstimatedNumbers(e) {
    this.setState({ estimated_numbers: e.target.value });
  }
  handleAdditionalQuestions(e) {
    this.setState({ additional_questions: e.target.value });
  }
  handleDietaryRequirements(e) {
    this.setState({ dietary_requirements: e.target.value });
  }

  // google sheet
  submitToGoogleSheets_school_lunch() {

     const currentDateTime =  new Date().toLocaleString().replace(",","").replace(/:.. /," ");
     const location = window.location.href;

      const FORM_GOOGLE_API_URL = 'https://script.google.com/macros/s/AKfycbz2M-C-JqA564vU0ci4qNBY61LNN4nHmA-_4ADbQbw_48K7RBgl/exec'
    + '?full_name=' + encodeURIComponent(this.state.full_name)
    + '&phone=' + encodeURIComponent(this.state.phone)
    + '&email=' + encodeURIComponent(this.state.email)
    + '&requested_dates=' + encodeURIComponent(this.state.requested_dates)
    + '&estimated_numbers=' + encodeURIComponent(this.state.estimated_numbers)
    + '&group_name=' + encodeURIComponent(this.state.group_name)
    + '&booking_type=' + encodeURIComponent(this.state.booking_type)
    + '&additional_questions=' + encodeURIComponent(this.state.additional_questions) 
    + '&dietary_requirements=' + encodeURIComponent(this.state.dietary_requirements)
    + '&location=' + location
    + '&date_time=' + currentDateTime

    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', FORM_GOOGLE_API_URL);
    httpRequest.send();
    console.log(FORM_GOOGLE_API_URL);
  }

  // handle form submission
   handleSubmit(event) { 

    event.preventDefault();
    let target_url = 'https://ys5upj67a0.execute-api.ap-southeast-2.amazonaws.com/contact';


    let form_fields = {
      full_name: this.state.full_name,
      phone: this.state.phone,
      email: this.state.email,
      requested_time: this.state.requested_time,
      requested_dates: this.state.requested_dates.toLocaleString('en-AU', { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' }),
      estimated_numbers: this.state.estimated_numbers,
      group_name: this.state.group_name,
      booking_type: this.state.booking_type,
      additional_questions: this.state.additional_questions,
      dietary_requirements: this.state.dietary_requirements
    }

    let xmlhttp = new XMLHttpRequest();   // new HttpRequest instance 
    xmlhttp.open("POST", target_url);
    xmlhttp.setRequestHeader("Content-Type", "application/json");
    xmlhttp.send(JSON.stringify(form_fields));
    event.currentTarget.reset();

   // this.submitToGoogleSheets_school_lunch();


    console.log('submitted');

    this.setState({
      status: 'success'
    });


  }


 

  render(){

  const { status } = this.state;

  return (


     <div className="contact-page">
     <ComponentHeader/>
       <Helmet>
            <title>School Lunch</title>
            <link rel="canonical" href="https://www.twinfin/school-lunch" />
            <meta name="description" content="We want to change the way people think about modern seafood and deliver an affordable, fun alternative. Contact us to find out more" />
        </Helmet>
         <div className="contact-wrapper">
          <div className="heading">
            <h2>School Lunch</h2>
          </div>
          <div className="content contact-wrapper">
            <form onSubmit={this.handleSubmit}>
              <div className="form-double-row">
                <div>
                  <label for="full-name">Full Name*</label>
                  <input 
                  type="text" 
                  id="full_name" 
                  name="full_name"
                  onChange={this.handleFullName}
                  required
                  />
                </div>
                <div>
                  <label for="phone">Phone*</label>
                 <input 
                 type="text" 
                 id="phone" 
                 name="phone"
                 onChange={this.handlePhone}
                 required
                 />
                </div>
              </div>
               <div className="form-double-row">
                <div>
                  <label>Email*</label>
                  <input 
                  type="email" 
                  id="email" 
                  name="email"
                  onChange={this.handleEmail}
                  required
                  />
                </div>
                <div>
                  <label>Requested Time</label>
                  <input 
                  type="text" 
                  id="time" 
                  name="time"
                  onChange={this.handleRequestedTime}
                  required
                  />
                </div>
                <div>
                
                </div>
              </div>
               <div className="form-double-row">
                <div>
                 <label for="requested-date">Requested Dates</label>
                  <DatePicker 
                  type="date" 
                  id="requested_dates" 
                  name="requested_dates"
                  selected={this.state.requested_dates}
                  onChange={this.handleRequestedDates}
                  
                  />
                </div>
                <div>
                 <label for="estimated-numbers">Estimated Numbers</label>
                 <input 
                 type="number" 
                 id="estimated_numbers" 
                 name="estimated_numbers"
                 onChange={this.handleEstimatedNumbers}
                 />
                </div>
              </div>
              <div className="form-double-row">
               <div>
                 <label for="estimated-numbers">School or Group Name</label>
                 <input 
                 type="text" 
                 id="group_name" 
                 name="group_name"
                 onChange={this.handleGroupName}
                 />
                </div>
                <div>
                 <label for="estimated-numbers">Booking Type</label>
                 <input 
                 type="text" 
                 id="booking_type" 
                 name="booking_type"
                 onChange={this.handleBookingType}
                 />
                </div>
                
              </div>
              <div className="form-row">
                <label for="message">Any Additional Questions</label>
                <textarea 
                name="additional_questions" 
                id="additional_questions"
                onChange={this.handleAdditionalQuestions}
                ></textarea>
              </div>
               <div className="form-row">
                <label for="diet-requirements">Dietary Requirements</label>
                <input 
                type="text" 
                name="dietary_requirements" 
                id="dietary_requirements" 
                onChange={this.handleDietaryRequirements}
                />
              </div>
              <div className="form-row">
                <input 
                type="submit" 
                id="submit" 
                className="submit-button"
                />
              </div>
            </form>
              {
                   status === 'success' &&
                   <div className="status">
                     <div className="flexMargin">
                       <p>Thank you for contacting us - someone from our team will get back to you shortly!</p>
                     </div>
                   </div>
                 }
          </div>
        </div>
      </div>
    );
  }
}