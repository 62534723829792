/*
  ComponentHeader.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import './MenuPage.scss';

// components
//import Menu from './../ComponentMenu';
import MenuPdf from './../MenuPdf';
import ComponentUberEats from './../ComponentUberEats';
import ComponentHeader from './../ComponentHeader';
import ComponentPop from './../ComponentPop';

export default class MenuPage extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {
   
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  

  }
 

  render(){


  return (


     <div className="menu-page">
     <ComponentHeader/>
       <Helmet>
          <title>Menu</title>
          <link rel="canonical" href="https://www.twinfin/menu" />
          <meta name="description" content="In an age where sustainability and “paddock-to-plate” are dominating global food trends, we’re happy to keep it simple and provide tasty food to share in a warm atmosphere" />
      </Helmet>
       <div className="heading">
            <h2 style={{marginBottom:'-15px'}}>Menu</h2>
            {/* <ComponentUberEats
            paddingBottom="0"
            marginTop="50px"
            /> */}
            <MenuPdf
            marginTop="0"
            />
          </div>
    </div>
    );
  }
}