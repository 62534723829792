////////////////////////////////////////////////
// =========== FORM SUBMISSION CODE.============
//
// Constants.
//
const CONTACT_URL = 'https://cajvufvld5.execute-api.ap-southeast-2.amazonaws.com/twinfin-careers-form';
const MAX_FILE_SIZE = 10000000;
//
// Code.
//
function convertFileToBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const arr = reader.result.split(';base64,');
      if (arr.length > 0) {
        resolve(arr[1]);
      } else {
        reject(new Error('FileReader base64 error'));
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}
export default async function submitContactForm (formData, attachment) {

  // Check for optional 'attachment'.
  let base64Attachment = null;
  if (attachment) {
    if (attachment.size > MAX_FILE_SIZE) {
      throw new Error(`File exceeds maximum of ${MAX_FILE_SIZE} Bytes.`);
    }
    base64Attachment = await convertFileToBase64(attachment);
  }

  // Submit form.
  const res = await fetch(CONTACT_URL, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      form: formData,
      attachment: attachment ? {
        name: attachment.name,
        type: attachment.type,
        data: base64Attachment,
      } : undefined,
    }),
  });
  const json = await res.json();

  if (res.status === 200) {
    return json;
  } else {
    throw new Error(json);
  }
}
// =============== END CODE ====================
////////////////////////////////////////////////
