import React, { Component } from "react"
// components
import ComponentHeader from '../Components/ComponentHeader';
import ComponentFooter from '../Components/ComponentFooter';

// pages 
import HomePage from '../Components/HomePage';
import AboutPage from '../Components/AboutPage';
import ContactPage from '../Components/ContactPage';
import MenuPage from '../Components/MenuPage';

import LandingPage from'../Components/LandingPage';
import GroupBookings from'../Components/GroupBookings';
import SchoolLunch from'../Components/SchoolLunch';
import Careers from'../Components/Careers';
import CovidPage from '../Components/CovidPage';

import NotFound from '../Components/NotFound';

import "./App.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/*
  Inner app.
*/

class AppInner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navIsOpen: false,
      showFooter: true,
      animate: '',
      location: this.props.location,
    };
  }

  componentDidMount() {
    this.middleAnimation();
  }

  componentDidUpdate(prevProps) {

    const prevPath = prevProps.location.pathname.toLowerCase();
    const nextPath = this.props.location.pathname.toLowerCase();

    if (prevPath !== nextPath) {
      this.startAnimation();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  startAnimation() {
    clearTimeout(this._timer);
    this.setState({ animate: 'animOut' });
    this._timer = setTimeout(this.middleAnimation.bind(this), 500);
  }

  middleAnimation() {
    this.setState({ animate: 'animIn', location: this.props.location });
    this._timer = setTimeout(this.endAnimation.bind(this), 500);
  }

  endAnimation() {
    this.setState({ animate: '' });
  }

  navToggle = (e) => {
    this.setState({
      navIsOpen:!this.state.navIsOpen
    })
  }

  handleFooter = (res) => {
    this.setState({
      showFooter: res
    })
  }

  render() {

    let className = 'react-router-wrapper';

    if (this.state.animate) {
      className += ' ' + this.state.animate;
    }



    return (
      <div className={className}>
        
          <div className="App">
            <div>
               <Switch>
                   <Route exact path='/' component={HomePage}></Route>
                   <Route exact path='/about' component={AboutPage}></Route>
                    <Route exact path='/menu' component={MenuPage}></Route>
                    <Route exact path='/school-lunch' component={SchoolLunch}></Route>
                    <Route exact path='/group-bookings' component={GroupBookings}></Route>
                    <Route exact path='/careers' component={Careers}></Route>
                    <Route exact path='/contact' component={ContactPage}></Route>
                     <Route exact path='/dining-info-covid' component={CovidPage}></Route>
                    <Route component={NotFound}/>
                </Switch>
            </div>
          </div>
          <ComponentFooter/>
      </div>
    );
  }
}

/*
  Outer router app.
*/

class App extends Component {
  render() {
    return (
      <Router>
        <Route component={AppInner}/>
      </Router>
    );
  }
}

export default App;

