/*
  ComponentFooter.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentFooter.scss';

// images

import Logo from '../../Media/svg/TWINFIN-LOGO.svg';
import Location from '../../Media/svg/LOCATION.svg';
import Stayhooked from '../../Media/svg/STAY.svg';

export default class ComponentFooter extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
    render(){


  return (
    <div className="component-footer">
      <div className="footer-wrapper">
        <div className="col-one">
         <h5 className="weight-bold">Opening Hours / Location</h5>
          <ul>
            <li>Twin Fin</li>
            <li><a href="#" target="_blank" rel="noreferrer">8/47 Mews Rd, Fremantle WA</a></li>
            <li>Monday - Thursday 11.00am - 8pm</li>
            <li>Friday 10:30am - 8:30pm</li>
            <li>Saturday 10am - 8:30pm</li>
            <li>Sunday 10am - 8pm</li>
          </ul>
        </div>
        <div className="col-two">
          <img src={Logo} alt="Twin Fin" className="footer-logo logo-desktop"/>
          <p className="logo-title">Restaurant & Bar</p>
        </div>
        <div className="col-three">
          <h5 className="weight-bold">Stay hooked</h5>
          <ul>
            <li><a href="https://www.facebook.com/TwinFinAU/" target="_blank" rel="noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/twinfin_au/" target="_blank" rel="noreferrer">Instagram</a></li>
            <li><Link to="/contact/"  rel="noreferrer">Contact</Link></li>
            <img src={Logo} alt="Twin Fin" className="footer-logo logo-mobile"/>
            <p className="logo-title logo-title-mobile">Restaurant & Bar</p>
            <li className="website-by"><a href="https://okmg.com" target="_blank" rel="noreferrer"> Website by okmg</a></li>
          </ul>
        </div>
      </div>

    </div>
    );
  }
}